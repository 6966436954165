import React from "react"

// components
import Layout from "@modules/layout"
import Seo from "@modules/seo"

import Leistungen from "@modules/leistungen"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

const IndexPage = ({ location, data }: any) => {
  const {
    organisationsentwicklung_startbild_l,
    organisationsentwicklung_startbild_m,
    organisationsentwicklung_startbild_s,
    fyler,
    kundenzufriedenheit_icon,
    feedback_icon,
    mitarbeitermotivation_icon,
    kulturwandel_icon,
    fylerPDF,
  } = data

  const organisationsentwicklung_startbildL: any = getImage(
    organisationsentwicklung_startbild_l
  )
  const organisationsentwicklung_startbildM: any = getImage(
    organisationsentwicklung_startbild_m
  )
  const organisationsentwicklung_startbildS: any = getImage(
    organisationsentwicklung_startbild_s
  )
  const fylerImg: any = getImage(fyler)

  return (
    <Layout location={location}>
      <Seo title="Organisationsentwicklung">
        <Leistungen
          sectionH1={
            <>
              <span className="outdoor">Organisations</span>
              <span className="outdoor">entwicklung</span>
            </>
          }
          sectionH2="Wir wollen die Veränderungen und Bewegungen in Organisationen unterstützen durch das Finden und Umsetzen einer attraktiven Zukunftsperspektive."
          introImage={organisationsentwicklung_startbildL}
          introImageS={organisationsentwicklung_startbildS}
          introImageM={organisationsentwicklung_startbildM}
          introImageAlt="test"
          intro={[
            {
              title: "Unsere Angebotspalette ",
              text: "Unsere Angebotspalette umfasst die Beratung und Begleitung komplexer Veränderungsprozesse, wie z.B. die Analyse und Optimierung der Unternehmenskultur, das Gesundheitsmanagement, die neue Führungskultur etc.",
            },
            {
              title: "Was wir unter nachhaltiger Veränderung verstehen",
              text: 'Organisationsentwicklung ist ein maßgeschneiderter Prozess unter Einbezug aller Beteiligten - eine "Verordnung von außen" kann nie nachhaltig sein.',
            },
            {
              title: "Wie wir vorgehen",
              text: "Auf Basis einer lösungsorientierten Auftragsklärung planen wir mit Ihnen gemeinsam die Change-Architektur, z.B. Zeitüberblick, Steuerungssysteme wie die Installierung von Entscheidungsgremien, konkrete Maßnahmen dafür etc. Im weiteren Schritt erfolgt die Umsetzung bedarfsgerechter Maßnahmen z.B. in Großveranstaltungen, Team-Entwicklungen Workshops, Coachings etc. Mit Follow-up Gesprächen und Evaluationen wird der Prozess abgeschlossen und nachhaltig gesichert. Sprechen Sie uns unverbindlich an! Wir freuen uns, mit Ihnen gemeinsam neue Wege zu gehen.",
            },
          ]}
          supportTitle="Wir Unterstützen Sie"
          supportImage={fylerImg}
          supportImageAlt="test"
          supportUl={[
            {
              icon: fylerImg,
              text: "Aufspüren und Verändern von kontraproduktiven Abläufen und Strukturen, z.B. durch Abteilungs-, Bereichs- bzw. Teamentwicklung",
            },
            {
              icon: fylerImg,
              text: "Beratung und Begleitung komplexer Veränderungsprozesse",
            },
            {
              icon: fylerImg,
              text: "Analyse und Optimierung der Unternehmenskultur, z.B. die Kommunikationskultur",
            },
          ]}
          profitTitle="Wie sie davon profitieren"
          quoteActive
          quote='"Was wir heute tun, entscheidet darüber, wie die Welt morgen aussieht."'
          quoteAuthor="- Marie von Ebner-Eschenbach"
          profitUl={[
            {
              icon: kundenzufriedenheit_icon,
              iconAlt: "test",
              text: "Erhöhung der Kundenzufriedenheit",
              key: kundenzufriedenheit_icon.childImageSharp.id,
            },
            {
              icon: mitarbeitermotivation_icon,
              iconAlt: "test",
              text: "Steigende Mitarbeitermotivation durch Stabilisierung der Mitarbeiter-gesundheit (Burnout-Prävention)",
              key: mitarbeitermotivation_icon.childImageSharp.id,
            },
            {
              icon: feedback_icon,
              iconAlt: "test",
              text: "Weiterentwicklung der Feedback-und Fehlerkultur",
              key: feedback_icon.childImageSharp.id,
            },
            {
              icon: kulturwandel_icon,
              iconAlt: "test",
              text: "Begleitung von Kulturwandel durch Entwicklung eines neuen (Führungs)-Leitbilds",
              key: kulturwandel_icon.childImageSharp.id,
            },
          ]}
          feedback={[
            {
              text: (
                <p>
                  "Das Team vom Ammerthaler Institut moderierte den
                  Organisationsentwicklungsprozess des Heilpädagogischen
                  Zentrums Amberg mit großem Erfolg. Christine Lehner und
                  Marianne Frye ergänzten einander in hervorragender Weise; so
                  dass neben einer klaren Strukturierung des Prozesses auch die
                  emotionalen Anteile aller Beteiligten immer Berücksichtigung
                  fanden. Die hausinternen Projektleiterinnen wurden in der
                  Entwicklung und Umsetzung von Maßnahmen ebenso fachkompetent
                  begleitet wie das Leitungsteam der Einrichtung."
                </p>
              ),
              author: "Isabel Wernekke",
              job: "Sonderschulrektorin und Leiterin des Heilpäd. Zentrums Amberg",
            },
            {
              text: (
                <p>
                  "Zwei Jahre begleitete uns das Ammerthaler Institut bei der
                  Organisationsentwicklung einer heilpädagogischen Tagestätte
                  der Lebenshilfe München. „Das Haus der Zukunft bauen“ lautete
                  das Motto, unter das Frau Weihe, Frau Lehner, Frau Frye und
                  Herr Graf die Organisationsentwicklung stellten. Nachfolgend
                  eine beispielhafte Sammlung von Stärken, die das Team um Frau
                  Lehner auszeichnen und wodurch die Organisationsentwicklung zu
                  einem gelungenen und großen Erfolg wurde: Geduld, Respekt,
                  Zusammenarbeit, Mut, Transparenz, Ausdauer, Kreativität,
                  Offenheit, Ideenvielfalt, Mut zum Konflikt."
                </p>
              ),
              author:
                "Sabine Refle, Lebenshilfe München Kinder und Jugend GmbH",
              job: "Koordinatorin HPT, SVE und Schule",
            },
          ]}
          recommendationTitle="Weitere Punkte unseres Leistungsspektrums"
          recommendataion={{
            outdoor: true,
            seminare_und_workshops: true,
            organisationsentwicklung: false,
            coaching: true,
          }}
          fylerActive
          fylerUrl={fylerPDF.publicURL}
          fylerButtonActive={true}
        />
      </Seo>
    </Layout>
  )
}

export const query = graphql`
  query {
    organisationsentwicklung_startbild_l: file(
      relativePath: { regex: "/organisationsentwicklung_startbild_l/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    organisationsentwicklung_startbild_m: file(
      relativePath: { regex: "/organisationsentwicklung_startbild_m/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    organisationsentwicklung_startbild_s: file(
      relativePath: { regex: "/organisationsentwicklung_startbild_s/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    fyler: file(relativePath: { regex: "/organisationsentwicklung_flyer2/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    kundenzufriedenheit_icon: file(
      relativePath: { regex: "/kundenzufriedenheit_icon/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    feedback_icon: file(relativePath: { regex: "/feedback_icon/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    mitarbeitermotivation_icon: file(
      relativePath: { regex: "/mitarbeitermotivation_icon/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    kulturwandel_icon: file(relativePath: { regex: "/kulturwandel_icon/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    fylerPDF: file(
      absolutePath: {
        regex: "/ammerthaler_institut_organisationsentwicklung_flyer/"
      }
    ) {
      publicURL
    }
  }
`

export default IndexPage
